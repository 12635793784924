
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../Utils/UseTranslation";


const SensorSpecifications = () => {
    const {t} = useTranslation()
    return (
        <>
            <Row>
                <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>

                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorAssayMethod")}</span>
                        <span> {t("sensorAssayMethod_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorReadingRange")}</span>
                        <span> {t("sensorReadingRange_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorSize")}</span> <span> {t("sensorSize_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorWeight")}</span> <span> {t("sensorWeight_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorPowerSource")}</span>
                        <span> {t("sensorPowerSource_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorData")}</span> <span> {t("sensorData_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorMemory")}</span> <span> {t("sensorMemory_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>

                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorTrasmission")}</span>
                        <span> {t("sensorTrasmission_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>

                </Col>
                <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>

                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("operatingTemp")}</span> <span> {t("operatingTemp_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorStorageTemp")}</span>
                        <span> {t("sensorStorageTemp_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("operatingHumidity")}</span>
                        <span> {t("operatingHumidity_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("sensorWaterResistance")}</span>
                        <span> {t("sensorWaterResistance_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("operatingStorageAltitude")}</span>
                        <span> {t("operatingStorageAltitude_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("radioFrequency")}</span> <span> {t("radioFrequency_Text")}</span></p>
                </Col>
            </Row>


        </>
    )
}

export default SensorSpecifications