import DateSelection from "./SetUp/DateSelection";
import {useEffect, useState} from "react";
import logo from './../../../assets/img/logo-FSL3.jpg'
import TimeSelection from "./SetUp/TimeSelection";
import ArrowDesc from "./SetUp/ArrowDesc";
import FirstHomeBtn from "./SetUp/FirstHomeBtn";
import HomeScreen from "./HomeScreens/HomeScreen";
import SensorCountdown from "./HomeScreens/SensorCountdown";
import GlucoseReadings from "./HomeScreens/GlucoseReadings";
import Notes from "./HomeScreens/Notes";
import NotesDetails from "./HomeScreens/NotesDetailsAdd";
import ReviewHistory from "./HomeScreens/ReviewHistory";
import GlucoseAlerts from "./HomeScreens/GlucoseAlerts";
import Logbook from "./HomeScreens/ReviewHistory/Logbook";
import Graphs from "./HomeScreens/ReviewHistory/Graphs";
import Settings from "./HomeScreens/Settings/Settings";
import AlarmsSettings from "./HomeScreens/Settings/Alarms/AlarmsSettings";
import AlarmsChange from "./HomeScreens/Settings/Alarms/AlarmsChange";
import {useTranslation} from "react-i18next";
import AlarmsInfos from "./HomeScreens/Settings/Alarms/AlarmsInfos";
import SoundsAndVibration from "./HomeScreens/Settings/SoundsAndVibration";
import TimeAndDate from "./HomeScreens/Settings/TimeAndDate";
import i18n from "../../../i18n";
import ReminderSettings from "./HomeScreens/Settings/ReminderSettings";
import LanguageSettings from "./HomeScreens/Settings/LanguageSettings";
import ReportSettings from "./HomeScreens/Settings/ReportSettings";
import ControlSolution from "./HomeScreens/Settings/ControlSolution";
import SystemStatus from "./HomeScreens/Settings/SystemStatus";
import DoseIncrement from "./HomeScreens/Settings/CalculatorSettings/DoseIncrement";
import InsulinCalculator from "./HomeScreens/Settings/CalculatorSettings/InsulinCalculator";
import CalculatorSetupOption from "./HomeScreens/Settings/CalculatorSettings/CalculatorSetupOption";
import EasySettingsSetup from "./HomeScreens/Settings/CalculatorSettings/EasySettingsSetup";
import CorrectionTarget from "./HomeScreens/Settings/CalculatorSettings/CorrectionTarget";
import CorrectionFactor from "./HomeScreens/Settings/CalculatorSettings/CorrectionFactor";
import AdvancedSettingsSetup from "./HomeScreens/Settings/CalculatorSettings/AdvancedSettingsSetup";
import UnitRangeOptional from "./HomeScreens/Settings/Elements/UnitRangeOptional";
import SetOptionalRange from "./HomeScreens/Settings/CalculatorSettings/SetOptionalRange";
import HowCorrect from "./HomeScreens/Settings/CalculatorSettings/HowCorrect";
import CorrectionTargetAdv from "./HomeScreens/Settings/CalculatorSettings/CorrectionTargetAdv";
import InsulinDuration from "./HomeScreens/Settings/CalculatorSettings/InsulinDuration";
import InsulinSymbol from "./HomeScreens/Settings/CalculatorSettings/InsulinSymbol";
import SetOptionalRangeTargetRange from "./HomeScreens/Settings/CalculatorSettings/SetOptionalRangeTargetRange";
import ReportingLimits from "./HomeScreens/Settings/ReportingLimits";
import ProfessionalOptions from "./HomeScreens/Settings/CalculatorSettings/ProfessionalOptions";
import CalculatorSettingsPage from "./HomeScreens/Settings/CalculatorSettings";
import {useOutletContext} from "react-router-dom";
import GlucoseAlarms from "../GlucoseAlarms";


const ScreenContainerFirstTime = (props) => {
    const [lang, setLang] = useState()
    const {t} = useTranslation('', {lng: lang});
    const [previousComponent, setPreviousComponent] = useState()
    // const [alarmSound, setAlarmSound] = useOutletContext()
    const [alarmSound, setAlarmSound] = useOutletContext()
    useEffect(() => {
        if (alarmSound === 'low' || alarmSound === 'high' || alarmSound === 'loss') {
            props.setCurrentStep('glucoseAlarms')
        }
        console.log(alarmSound, props.turnOnOff)
    }, [alarmSound, props.turnOnOff])
    const changeLanguage = (lng) => {
        setLang(lng)
        i18n.getFixedT('reader', null, lng);
    };
    const [showImage, setShowImage] = useState(true);
    /*Date and Time*/
    const [day, setDay] = useState(1);
     const months = ''
    const [monthsFormat, setMonthsFormat] = useState();
    /*Per la selezione della data, devo adattare le lingue per poter prendere la giusta traduzione per i casi US - CH e AT */
    useEffect(() => {
        // Mappatura tra il nome della cartella delle traduzioni e il codice della lingua
        const languageMap = {
            "en": "en",
            "us": "en-US", // Cambia "us" con il nome effettivo della tua cartella per la lingua "US"
            "at": "de-AT", // Tedesco in Austria
            "ch_it": "it-IT", // Italiano in Svizzera
            "ch_de": "de-DE", // Tedesco in Svizzera
            "ch_fr": "fr-FR", // Francese in Svizzera
            // Aggiungi altre lingue se necessario
        };

        // Ottieni il nome della cartella delle traduzioni
        const folderName = i18n.language.split('-')[0];

        // Determina il codice della lingua corrispondente
        const languageCode = languageMap[folderName] || i18n.language;
        console.log('langCode', languageCode)
        // Aggiorna il formato del mese in base alla lingua corrispondente
         setMonthsFormat(new Intl.DateTimeFormat(languageCode, {month: 'long'}));

    }, [i18n.language]);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentHour, setCurrentHour] = useState(new Date().getHours());
    const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes());
    /*Reminders */
    const [reminderType, setReminderType] = useState(t('checkGlucose')
    )
    const [reminderRepeat, setReminderRepeat] = useState(t('daily'))
    const [reminderHour, setReminderHour] = useState(new Date().getHours())
    const [reminderMinute, setReminderMinute] = useState(new Date().getMinutes())
    const [fromReminder, setFromReminder] = useState(false)
    const [allReminders, setAllReminders] = useState([])
    const [reminderAlreadySet, setReminderAlreadySet] = useState(false)

    const [stepHistory, setStepHistory] = useState([]);
    const [firstActivation, setFirstActivation] = useState(props.sensorIsAlreadyActive ? false : true)
    const [sensorIsActive, setSensorIsActive] = useState(
        props.sensorIsAlreadyActive ? true : localStorage.getItem('sensorIsActive') ? localStorage.getItem('sensorIsActive') : false)
    useEffect(() => {
        console.log(sensorIsActive)
    }, [sensorIsActive])
    /*Valori per letture glucosio*/
    const [behaviorIndex, setBehaviorIndex] = useState(0);
    const behavior = sensorIsActive ? ['GoingHigh', 'inRange', 'goingLow', 'High', 'Low'] : ['noActive']
    /*Aggiungi nota a lettura glucosio*/
    const [noteDetails, setNoteDetails] = useState('');


    const [glucoseReading, setGlucoseReading] = useState([{
        glucoseValue: 'inRange',
        numericValue: '72',
        rapidActing: 2,
        longActing: 3,
        date: '2024-02-22T13:16:33.779Z'
    }, {
        glucoseValue: 'High',
        numericValue: '270',
        rapidActing: 2,
        longActing: 3,
        date: '2024-04-12T13:16:33.779Z'
    },
        {
            glucoseValue: 'Low',
            numericValue: '65',
            rapidActing: 2,
            longActing: 5,
            date: '2024-03-22T13:16:33.779Z'
        }
    ])

    const [graphType, setGraphType] = useState()

    const [alarmType, setAlarmType] = useState()
    const [alarmsSettings, setIsAlarmSettings] = useState(false);
    const [alarmsValues, setAlarmsValues] = useState({})
    const [alarmActivated, setAlarmActivated] = useState()
    const [activeSounds, setActiveSounds] = useState({
        systemSound: false,
        volume: false,
        vibration: false,
        touchSounds: false
    });
    const [isFromSettings, setIsFromSettings] = useState()

    /*Report Settings */
    const [targetRange, setTargetRange] = useState({
        min: parseInt(t('dynamicValues.targetRangeSetup.min')),
        max: parseInt(t('dynamicValues.targetRangeSetup.max'))
    })
    /*reportingLimits Settings */
    const [reportLimit, setReportLimit] = useState({
        min: parseInt(t('dynamicValues.reportLimitSetup.min')),
        max: parseInt(t('dynamicValues.reportLimitSetup.max'))
    })

    console.log(props.currentStep)
    /*Calculator Settings*/
    const defaultValueCorrection = t("dynamicValues.unit") === 'mg/dL' ? 50 : 1.5
    const [calculatorOff, setCalculatorOff] = useState(false)

    const [calculatorSettings, setCalculatorSettings] = useState({
        calculatorMode: 'easy',
        doseIncrement: '1',
        easyMode: {
            breakfast: 14,
            lunch: 14,
            dinner: 14,
            correctionTarget: {
                min: parseInt(t("dynamicValues.correctionTarget.low")),
                max: parseInt(t("dynamicValues.correctionTarget.high"))
            },
            correctionFactor: '',
        },
        advancedMode: {
            servingsOrCarboRatio: 'carbo',
            carbRatio: null,
            carbRatioByTime: {morning: null, midday: null, evening: null, night: null},
            servingsRatio: null,
            servingsRatioByTime: {morning: null, midday: null, evening: null, night: null},
            correctionTarget: null,
            correctionTargetRange: {
                min: parseInt(t("dynamicValues.targetRangeSetup.min")),
                max: parseInt(t("dynamicValues.targetRangeSetup.max"))
            },
            correctionTargetRangeByTime: {
                morning: {
                    min: null,
                    max: null
                },
                midday: {
                    min: null,
                    max: null
                },
                evening: {
                    min: null,
                    max: null
                },
                night: {
                    min: null,
                    max: null
                }
            },
            correctionByTime: {
                morning: null,
                midday: null,
                evening: null,
                night: null
            },
            correctionFactor: null,
            correctionFactorByTime: {
                morning: null,
                midday: null,
                evening: null,
                night: null
            },
            insulinDuration: {
                hrs: 4,
                min: 30
            },
            activeInsulinSymbol: 'disabled'
        }
    })
    const [isFromCalculator, setIsFromCalculator] = useState(false)
    const [optionalSettingsAdv, setOptionalSettingsAdv] = useState({})
    const [foodType, setFoodType] = useState('grams');
    const [property, setProperty] = useState(calculatorSettings && calculatorSettings[`${calculatorSettings.calculatorMode + 'Mode'}`][`${foodType === 'grams' ? 'carbRatio' + 'ByTime' : 'servingsRatio' + 'ByTime'}`])
    useEffect(() => {
        setProperty(calculatorSettings && calculatorSettings[`${calculatorSettings.calculatorMode + 'Mode'}`][`${foodType === 'grams' ? 'carbRatio' + 'ByTime' : 'servingsRatio' + 'ByTime'}`])

    }, [calculatorSettings, foodType, optionalSettingsAdv, property])


    useEffect(() => {
        console.log(optionalSettingsAdv)
    }, [optionalSettingsAdv])


    useEffect(() => {
        if (!props.fromSettingAlarms) {
            const timer = setTimeout(() => {
                setShowImage(false);
                props.setCurrentStep('date');
            }, 3000); // Mostra l'immagine per 3 secondi

            return () => clearTimeout(timer);
        }

    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentHour(new Date().getHours());
            setCurrentMinute(new Date().getMinutes());
        }, 60000); // Aggiorna ogni minuto

        return () => clearInterval(intervalId);
    }, []);


    const handleNextStep = (step) => {
        setStepHistory([...stepHistory, props.currentStep]);
        props.setCurrentStep(step);
    };

    const handlePrevStep = () => {
        const previousStep = stepHistory.pop();
        console.log(stepHistory)
        props.setCurrentStep(previousStep);
        setStepHistory([...stepHistory]); // Aggiorna lo storico degli step
    };

    const navigateToComponent = (componentName) => {
        props.setCurrentStep(componentName);
        setPreviousComponent(props.currentStep)
    };

    useEffect(() => {
        console.log(props.currentStep)
        console.log('prev', previousComponent)
    }, [props.currentStep])
    return (
        <>
            <>
                {props.currentStep === 0 && (
                    <img src={logo} alt="Immagine" className={'img-fluid w-75'}/>
                )}
                {props.currentStep === 'date' && (
                    <DateSelection navigateToComponent={navigateToComponent} handlePrevStep={handlePrevStep} day={day}
                                   setCurrentMonth={setCurrentMonth}
                                   setCurrentYear={setCurrentYear} currentMonth={currentMonth} currentYear={currentYear}
                                   setDay={setDay} months={months} isFromSettings={isFromSettings}
                                   monthsFormat={monthsFormat}/>
                )}
                {props.currentStep === 'time' && (
                    <TimeSelection navigateToComponent={navigateToComponent} handlePrevStep={handlePrevStep}
                                   currentHour={currentHour} currentMinute={currentMinute}
                                   setCurrentHour={setCurrentHour} setCurrentMinute={setCurrentMinute}
                                   isFromSettings={isFromSettings} reminderMinute={reminderMinute}
                                   setReminderMinute={setReminderMinute} reminderHour={reminderHour}
                                   setReminderHour={setReminderHour} fromReminder={fromReminder}/>
                )}
                {props.currentStep === 'arrowDesc' && (
                    <ArrowDesc navigateToComponent={navigateToComponent} isFromSettings={isFromSettings}/>
                )}
                {props.currentStep === 'firstHomeBtn' && (
                    <FirstHomeBtn navigateToComponent={navigateToComponent} isFromSettings={isFromSettings}
                                  setIsFromSettings={setIsFromSettings}/>
                )}
                {props.currentStep === 'homeScreen' && (
                    <HomeScreen navigateToComponent={navigateToComponent} firstActivation={firstActivation}
                                setHomeBtnBehavior={props.setHomeBtnBehavior(true)}
                                setBehaviorIndex={setBehaviorIndex}
                                sensorIsActive={sensorIsActive}
                                behaviorIndex={behaviorIndex}
                                behavior={behavior} setGlucoseReading={setGlucoseReading}
                                glucoseReading={glucoseReading} t={t} lang={lang}
                                activeInsulin={calculatorSettings && calculatorSettings.advancedMode.activeInsulinSymbol === 'enabled'}/>
                )}
                {props.currentStep === 'startSensor' && (
                    <SensorCountdown navigateToComponent={navigateToComponent} setFirstActivation={setFirstActivation}
                                     sensorIsActive={sensorIsActive} setSensorIsActive={setSensorIsActive}
                                     firstActivation={firstActivation}/>
                )}
                {props.currentStep === 'viewGlucose' && (
                    <GlucoseReadings navigateToComponent={navigateToComponent} setFirstActivation={setFirstActivation}
                                     sensorIsActive={sensorIsActive}
                                     setSensorIsActive={setSensorIsActive}
                                     firstActivation={firstActivation}
                                     behaviorIndex={!props.behaviorIndex ? behaviorIndex : props.behaviorIndex}
                                     behavior={!props.behavior ? behavior : props.behavior}
                                     isFromAddingNotes={props.isFromAddingNotes}/>
                )}

                {props.currentStep === 'notes' && (
                    <Notes navigateToComponent={navigateToComponent} setNoteDetails={setNoteDetails}/>
                )}
                {props.currentStep === 'notesDetail' && (
                    <NotesDetails navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                  noteDetails={noteDetails}/>
                )}
                {props.currentStep === 'reviewHistory' && (
                    <ReviewHistory navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                   setGraphType={setGraphType}/>
                )}
                {props.currentStep === 'glucoseAlerts' && (
                    <GlucoseAlerts navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                   noteDetails={noteDetails} behaviorIndex={behaviorIndex}
                                   behavior={behavior}/>
                )}
                {props.currentStep === 'logbook' && (
                    <Logbook navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                             firstActivation={firstActivation} sensorIsActive={sensorIsActive}
                             glucoseReading={glucoseReading}/>
                )}

                {props.currentStep === 'graphs' && (
                    <Graphs navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                            grapType={graphType} firstActivation={firstActivation} sensorIsActive={sensorIsActive}/>
                )}
                {props.currentStep === 'settings' && (
                    <Settings navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                              setGraphType={setGraphType} calculatorOff={calculatorOff}
                              setIsFromSettings={setIsFromSettings} isFromProfessional={'isFromProfessiona'}/>
                )}
                {props.currentStep === 'alarmsSettings' && (
                    <AlarmsSettings navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                    setGraphType={setGraphType} setAlarmType={setAlarmType}
                                    alarmsSettings={alarmsSettings} setIsAlarmSettings={setIsAlarmSettings}
                                    alarmsValues={alarmsValues} setAlarmActivated={setAlarmActivated}
                                    alarmActivated={alarmActivated}/>
                )}
                {props.currentStep === 'alarmsChange' && (
                    <AlarmsChange navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                  alarmType={alarmType} setIsAlarmSettings={setIsAlarmSettings}
                                  alarmsValues={alarmsValues}
                                  setAlarmsValues={setAlarmsValues} setAlarmActivated={setAlarmActivated}
                                  alarmActivated={alarmActivated}/>
                )}
                {props.currentStep === 'alarmsInfos' && (
                    <AlarmsInfos navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                 alarmType={alarmType} setIsAlarmSettings={setIsAlarmSettings}
                                 alarmsValues={alarmsValues}
                                 setAlarmsValues={setAlarmsValues} setAlarmActivated={setAlarmActivated}
                                 alarmActivated={alarmActivated} prev={previousComponent}/>
                )}
                {props.currentStep === 'soundsAndVibration' && (
                    <SoundsAndVibration navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                        setActiveSounds={setActiveSounds} activeSounds={activeSounds}
                                        setAlarmActivated={setAlarmActivated} alarmActivated={alarmActivated}/>
                )}
                {props.currentStep === 'timeDate' && (
                    <TimeAndDate navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                 day={day} setCurrentMonth={setCurrentMonth} setCurrentYear={setCurrentYear}
                                 currentMonth={currentMonth} currentYear={currentYear} setDay={setDay}
                                 currentHour={currentHour} currentMinute={currentMinute} months={months}
                                 setIsFromSettings={setIsFromSettings}
                    />
                )}
                {props.currentStep === 'reminderSettings' && (
                    <ReminderSettings navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                      currentHour={currentHour} currentMinute={currentMinute}
                                      setIsFromSettings={setIsFromSettings} setFromReminder={setFromReminder}
                                      fromReminder={fromReminder} reminderHour={reminderHour}
                                      reminderMinute={reminderMinute} reminderType={reminderType}
                                      setReminderType={setReminderType} reminderRepeat={reminderRepeat}
                                      setReminderRepeat={setReminderRepeat} allReminders={allReminders}
                                      setAllReminders={setAllReminders}
                                      setReminderAlreadySet={setReminderAlreadySet}
                                      reminderAlreadySet={reminderAlreadySet}
                                      alarmActivated={alarmActivated} setAlarmActivated={setAlarmActivated}

                    />
                )}
                {props.currentStep === 'language' && (
                    <LanguageSettings navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                      day={day} setCurrentMonth={setCurrentMonth} setCurrentYear={setCurrentYear}
                                      currentMonth={currentMonth} currentYear={currentYear} setDay={setDay}
                                      currentHour={currentHour} currentMinute={currentMinute} months={months}
                                      setIsFromSettings={setIsFromSettings} t={t} changeLanguage={changeLanguage}
                    />
                )}
                {props.currentStep === 'reportSettings' && (
                    <ReportSettings navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                    targetRange={targetRange} setTargetRange={setTargetRange}
                                    setIsFromCalculator={setIsFromCalculator} isFromCalculator={isFromCalculator}
                                    calculatorSettings={calculatorSettings}
                                    setCalculatorSettings={setCalculatorSettings}/>
                )}
                {props.currentStep === 'reportingLimits' && (
                    <ReportingLimits navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                     reportLimit={reportLimit} setReportLimit={setReportLimit}
                                     setIsFromCalculator={setIsFromCalculator} isFromCalculator={isFromCalculator}
                                     calculatorSettings={calculatorSettings}
                                     setCalculatorSettings={setCalculatorSettings}/>
                )}
                {props.currentStep === 'controlSolution' && (
                    <ControlSolution navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                     currentHour={currentHour} currentMinute={currentMinute}
                    />
                )}
                {props.currentStep === 'systemStatus' && (
                    <SystemStatus navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                  currentHour={currentHour} currentMinute={currentMinute}
                    />
                )}
                {props.currentStep === 'calculatorSettings' && (
                    <CalculatorSettingsPage navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                            calculatorSettings={calculatorSettings}
                                            setCalculatorSettings={setCalculatorSettings}/>
                )}
                {props.currentStep === 'professionalOptions' && (
                    <ProfessionalOptions navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                         calculatorSettings={calculatorSettings}
                                         setCalculatorSettings={setCalculatorSettings}
                                         setCalculatorOff={setCalculatorOff}
                                         isFromProfessional={'isFromProfessional'}/>
                )}
                {props.currentStep === 'doseIncrement' && (
                    <DoseIncrement navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                   calculatorSettings={calculatorSettings}
                                   setCalculatorSettings={setCalculatorSettings} t={t}/>
                )}
                {props.currentStep === 'insulinCalculator' && (
                    <InsulinCalculator navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                       calculatorSettings={calculatorSettings}
                                       setCalculatorSettings={setCalculatorSettings} t={t}
                                       setCalculatorOff={setCalculatorOff}/>
                )}
                {props.currentStep === 'calculatorSetup' && (
                    <CalculatorSetupOption navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                           calculatorSettings={calculatorSettings}
                                           setCalculatorSettings={setCalculatorSettings} t={t}
                                           setCalculatorOff={setCalculatorOff} setAlarmActivated={setAlarmActivated}
                                           alarmActivated={alarmActivated}/>
                )}
                {props.currentStep === 'easyModeSetup' && (
                    <EasySettingsSetup navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                       calculatorSettings={calculatorSettings}
                                       setCalculatorSettings={setCalculatorSettings} t={t}
                                       setIsFromCalculator={setIsFromCalculator}/>
                )}
                {props.currentStep === 'correctionTarget' && (
                    <CorrectionTarget navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                      calculatorSettings={calculatorSettings}
                                      setCalculatorSettings={setCalculatorSettings} t={t}
                                      setIsFromCalculator={setIsFromCalculator}/>
                )}
                {props.currentStep === 'correctionFactor' && (
                    <CorrectionFactor navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                      calculatorSettings={calculatorSettings}
                                      setCalculatorSettings={setCalculatorSettings} t={t}
                                      setIsFromCalculator={setIsFromCalculator} previousComponent={previousComponent}/>
                )}
                {props.currentStep === 'advancedModeSetup' && (
                    <AdvancedSettingsSetup navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                           calculatorSettings={calculatorSettings}
                                           setCalculatorSettings={setCalculatorSettings} t={t}
                                           setIsFromCalculator={setIsFromCalculator}
                                           optionalSettingsAdv={optionalSettingsAdv}
                                           setOptionalSettingsAdv={setOptionalSettingsAdv}
                                           previousComponent={previousComponent} foodType={foodType}
                                           setFoodtype={setFoodType} property={property} setPropery={setProperty}/>
                )}
                {props.currentStep === 'optionalRange' && (
                    <UnitRangeOptional navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                       calculatorSettings={calculatorSettings}
                                       setCalculatorSettings={setCalculatorSettings} t={t}
                                       setIsFromCalculator={setIsFromCalculator}
                                       optionalSettingsAdv={optionalSettingsAdv}
                                       setOptionalSettingsAdv={setOptionalSettingsAdv}
                                       previousComponent={previousComponent} property={property}/>
                )}
                {props.currentStep === 'detailOptionalRange' && (
                    <SetOptionalRange navigateToComponent={navigateToComponent} currentStep={props.currentStep} t={t}
                                      calculatorSettings={calculatorSettings}
                                      setCalculatorSettings={setCalculatorSettings}
                                      setIsFromCalculator={setIsFromCalculator}
                                      optionalSettingsAdv={optionalSettingsAdv}
                                      setOptionalSettingsAdv={setOptionalSettingsAdv}
                                      previousComponent={previousComponent} property={property}/>
                )}
                {props.currentStep === 'optionalRangeTargetRange' && (
                    <SetOptionalRangeTargetRange navigateToComponent={navigateToComponent}
                                                 currentStep={props.currentStep}
                                                 calculatorSettings={calculatorSettings}
                                                 setCalculatorSettings={setCalculatorSettings} t={t}
                                                 setIsFromCalculator={setIsFromCalculator}
                                                 optionalSettingsAdv={optionalSettingsAdv}
                                                 setOptionalSettingsAdv={setOptionalSettingsAdv}
                                                 previousComponent={previousComponent} property={property}/>
                )}
                {props.currentStep === 'howCorrect' && (
                    <HowCorrect navigateToComponent={navigateToComponent} currentStep={props.currentStep} t={t}
                                calculatorSettings={calculatorSettings}
                                setCalculatorSettings={setCalculatorSettings}
                                setIsFromCalculator={setIsFromCalculator}
                                optionalSettingsAdv={optionalSettingsAdv}
                                setOptionalSettingsAdv={setOptionalSettingsAdv}
                                previousComponent={previousComponent} property={property}/>
                )}
                {props.currentStep === 'correctionTargetAdv' && (
                    <CorrectionTargetAdv navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                         calculatorSettings={calculatorSettings}
                                         setCalculatorSettings={setCalculatorSettings} t={t}
                                         setIsFromCalculator={setIsFromCalculator}
                                         optionalSettingsAdv={optionalSettingsAdv}
                                         setOptionalSettingsAdv={setOptionalSettingsAdv}
                                         previousComponent={previousComponent}/>
                )}
                {props.currentStep === 'setInsulinDuration' && (
                    <InsulinDuration navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                     calculatorSettings={calculatorSettings}
                                     setCalculatorSettings={setCalculatorSettings} t={t}
                                     setIsFromCalculator={setIsFromCalculator}
                                     optionalSettingsAdv={optionalSettingsAdv}
                                     setOptionalSettingsAdv={setOptionalSettingsAdv}
                                     previousComponent={previousComponent}/>
                )}
                {props.currentStep === 'insulinSymbol' && (
                    <InsulinSymbol navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                   calculatorSettings={calculatorSettings}
                                   setCalculatorSettings={setCalculatorSettings} t={t}
                                   setIsFromCalculator={setIsFromCalculator}
                                   optionalSettingsAdv={optionalSettingsAdv}
                                   setOptionalSettingsAdv={setOptionalSettingsAdv}
                                   previousComponent={previousComponent}/>
                )}

                {props.currentStep === 'glucoseAlarms' && (
                    <GlucoseAlarms navigateToComponent={navigateToComponent} currentStep={props.currentStep}
                                   alarmSound={alarmSound} setAlarmSound={setAlarmSound}
                    />
                )}

            </>
        </>


    )
}

export default ScreenContainerFirstTime