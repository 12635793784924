import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import controlSolution_1 from "../../../assets/img/Reader/Settings/control_Solution_1.jpg";
import controlSolution_2 from "../../../assets/img/Reader/Settings/control_Solution_2.jpg";
import controlSolution_3 from "../../../assets/img/Reader/Settings/control_Solution_3.jpg";
import waiting_1 from "../../../assets/img/Reader/Settings/waiting1.jpg";
import waiting_2 from "../../../assets/img/Reader/Settings/waiting2.jpg";
import waiting_3 from "../../../assets/img/Reader/Settings/waiting3.jpg";
import {Col, Row} from "react-bootstrap";
import {CiBatteryFull} from "react-icons/ci";

const CheckingAnimation = (props) => {
    const {t, i18n} = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [loopImage, setLoopImage] = useState(false)
    let imageArray = [waiting_1, waiting_2, waiting_3,waiting_1, waiting_2, waiting_3];
    const intervalIdRef = useRef(null);
    const [repeatCount, setRepeatCount] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
        }, 1000); // Cambia immagine ogni 3 secondi

        // Salva intervalId nella variabile ref
        intervalIdRef.current = intervalId;

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        // Controlla se abbiamo visualizzato l'ultima immagine e ferma l'intervallo
        if (currentImageIndex >= imageArray.length - 1) {
            setTimeout(() => {
                if (repeatCount < 1) {
                    setCurrentImageIndex(3); // Ripeti le ultime tre immagini
                    setRepeatCount(prevCount => prevCount + 1);
                } else {
                    clearInterval(intervalIdRef.current);
                    setLoopImage(true);
                }
            }, 500);
        }
    }, [currentImageIndex, repeatCount]);


    return (
        <Row className={'mx-0 h-100 text-start justify-content-start align-items-start overflow-hidden'}>


            {!loopImage ? (
                <Col className={'px-0'}>
                    <img src={imageArray[currentImageIndex]} alt="Immagine" className={'img-fluid'}/>
                </Col>
            ) : (
                <>
                    <Col md={12} className={''}
                         style={{background: '#dedede', height: '50px', boxShadow: '1px 1px rgba(0,0,0,0.4)'}}>
                        <span className={'small me-2'}>{props.currentHour}:{props.currentMinute}</span>
                        <CiBatteryFull/>
                    </Col>
                    <Row className={'align-items-center mx-0'}>
                        <Col md={9} className={'pe-0'}>
                            <h2 className={'text-end fw-bold h1'}
                                style={{fontSize: '70px'}}>{t("dynamicValues.controlSolution")}</h2>
                        </Col>
                        <Col md={3} className={'pe-1 text-center'}>
                            <p className={'mb-0 px-1'}
                               style={{borderBottom: '1px solid #333'}}>{t("dynamicValues.splittedUnit.first")}</p>
                            <p className={'mb-0 px-1'}>{t("dynamicValues.splittedUnit.second")}</p>
                        </Col>
                    </Row>


                    <Col md={12} className={'text-center'}>
                        <p>{t("controlSolution")}</p>
                    </Col>
                </>
            )}
        </Row>

    )
}

export default CheckingAnimation